<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <Form :data=empty @save="onSave" :save_text="$pgettext('form','Create')"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Form from "@/domain/plans/components/Form.vue";
import {SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'NewPlan',
  components: {Form},
  data() {
    return {
      empty: {
        name: null,
        settings: {
          num_photo_included: null,
          extra_photo_price: null,
          num_max_photo: null,
          max_participants: null,
          is_promo: false,
        }
      }
    }
  },
  methods: {
    ...mapActions('plan', [
      'createPlan',
    ]),
    async onSave(data) {
      await this.createPlan(data)
      await this.$router.push({name: 'payments.plan.list'})
      throw new SuccessNotification(this.$pgettext('plan', 'Plan created!'))
    }
  }
}
</script>

<style scoped>

</style>