<template>
  <div>
    <CCard>
      <CCardHeader>
        <div class="card-header-actions">
          <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                   translate-context="form" class="mr-2">Reset
          </CButton>
          <CButton color="primary" @click="onSave" ref="save-button">{{ save_text }}</CButton>
        </div>
      </CCardHeader>
      <CCardBody class="p-0">
        <CContainer fluid class="grid-stripped">
          <PFRow :label="$pgettext('plan.label','Name')">
            <CInput v-model="form.name"/>
          </PFRow>
        </CContainer>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <div class="card-header-actions">
          <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                   translate-context="form" class="mr-2">Reset
          </CButton>
          <CButton color="primary" @click="onSave" ref="save-button">{{ save_text }}</CButton>
        </div>
      </CCardHeader>
      <CCardBody class="p-0">
        <CContainer fluid class="grid-stripped">
          <CRow>
            <CCol>
              Se il numero di "incluse" è vuoto, significa che è illimitato, 0 implica nessun caricamento possibile<br>
              Se extra block size è vuoto o il suo prezzo è vuoto, impedisce il caricamento oltre il limite
            </CCol>
          </CRow>
          <CRow>
            <CCol><h5 v-translate translate-context="plan.section">Photo</h5></CCol>
          </CRow>
          <PFRow :label="$pgettext('plan.label','Included')">
            <CInput v-model="form.settings.num_photo_included" type="number"/>
          </PFRow>
          <PFRow :label="$pgettext('plan.label','Price for extra media')">
            <CInput v-model="form.settings.extra_photo_price" type="number"/>
          </PFRow>
          <PFRow :label="$pgettext('plan.label','Max uploadable media')">
            <CInput v-model="form.settings.num_max_photo" type="number"/>
          </PFRow>
          <PFRow :label="$pgettext('plan.label','Max participants for welcome code')">
            <CInput v-model="form.settings.max_participants" type="number"/>
          </PFRow>
          <PFRow :label="$pgettext('plan.label','Is Promo')">
            <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.settings.is_promo"/>
          </PFRow>
        </CContainer>
      </CCardBody>
    </CCard>
  </div>


</template>

<script>
import PFRow from "@/domain/core/components/PFRow.vue";
import {cloneDeep} from "lodash";

export default {
  name: "Form",
  components: { PFRow},
  props: {
    data: {
      type: Object,
      required: true
    },
    save_text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        name: null,
        settings: {
          num_photo_included: null,
          extra_photo_price: null,
          num_max_photo: null,
          max_participants: null,
          is_promo: false,
        }
      },
    }
  },
  async mounted() {
    this.onReset()
  },
  emits: ['save'],
  methods: {
    onReset() {
      this.form = cloneDeep(this.data)
    },
    async onSave() {
      this.$emit('save', cloneDeep(this.form))
    }
  }
}
</script>